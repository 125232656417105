<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-db-setting-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "LocalizationLanguages.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="localizationLanguageData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="LanguageCulture" class="form-label required"
                >{{
                  $t(
                    "LocalizationLanguages.LanguageCulture",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="LanguageCulture"
                v-model="localizationLanguageData.languageCulture"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="LanguageIcon" class="form-label required"
                >{{
                  $t(
                    "LocalizationLanguages.LanguageIcon",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <LanguageFlagSelectBox
                v-if="getDataFinished"
                @onSelectedValue="getLocaleValue"
                :data="localizationLanguageData"
                :isEdit="true"
              />
            </div>
            <div class="col-md-6">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "LocalizationLanguages.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="localizationLanguageData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              v-if="this.$store.getters._isSuperAdmin"
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import LanguageFlagSelectBox from "@/components/custom/locale-switchers/LanguageFlagSelectBox.vue";
export default {
  name: "LocalizationLanguageEdit",
  components: {
    LanguageFlagSelectBox,
  },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      localizationLanguageData: {},
      isSuccess: false,
      languageIconName: "",
      getDataFinished: false,
    };
  },
  methods: {
    getLocaleValue(selectedValue) {
      debugger;
      this.localizationLanguageData.languageIcon = selectedValue.path;
    },

    onClear() {
      this.keyItemTypeId = 1;
      $(".form-db-setting-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-db-setting-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("Brs-LocalizationLanguageUpdate", {
          ...this.localizationLanguageData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/LocalizationLanguage/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getLocalizationLanguageData() {
      this.getDataFinished = false;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-LocalizationLanguageGet?id={0}",
            this.$route.params.localizationLanguageId
          )
        )
        .then((response) => {
          this.localizationLanguageData = response.data;
          this.getDataFinished = true;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getLocalizationLanguageData();
  },
};
</script>
