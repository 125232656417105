<template>
  <a
    class="nav-link dropdown-toggle"
    href="#"
    id="LanguageIcon"
    data-bs-toggle="dropdown"
  >
    <div v-if="isEdit">
      <img
        :src="
          require(`@/assets/images/flags/${data.languageIcon.split('/')[4]}`)
        "
        :alt="locale.name"
        :width="25"
      />
      {{ data.languageIcon.split("/")[4].split(".")[0] }}
    </div>
    <div v-else>
      <img
        :src="require(`@/assets/images/flags/${locale.file}`)"
        :alt="locale.name"
        :width="25"
      />
      {{ locale.text }}
    </div>
  </a>
  <ul
    class="dropdown-menu dropdown-multicol lsb-max-height filter-dropdowns"
    aria-labelledby="LanguageIcon"
  >
    <li class="dropdown-item bg-primary">
      <input
        type="text"
        class="form-control"
        @keyup="searchLanguage"
        v-model="query"
        :placeholder="
          $t('Header.Search', {}, { locale: this.$store.state.activeLang })
        "
      />
    </li>
    <li
      class="dropdown-item cursor-pointer"
      v-for="(locale, i) in locales.filter(function (f) {
        return f.file != locale.file;
      })"
      :key="i"
      @click="changeLocale(locale)"
    >
      <img
        :src="require(`@/assets/images/flags/${locale.file}`)"
        :alt="locale.code"
        :width="25"
      />
      {{ locale.text }}
    </li>
  </ul>
</template>
<script>
import $ from "jquery";
export default {
  name: "LanguageFlagSelectBox",
  emits: ["onSelectedValue"],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    isEdit: { type: Boolean, default: false },
  },
  data() {
    return {
      locales: [],
      query: null,
      locale: {
        file: "tr.png",
        name: "tr",
        text: this.$t(
          "Components.LanguageFlagSelectBox.tr",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      filePath: "@/assets/images/flags/",
    };
  },
  methods: {
    changeLocale(locale) {
      this.locale = locale;
      debugger;
      this.$emit("onSelectedValue", this.locale);
    },
    searchLanguage() {
      var value = this.query.toLowerCase();

      $(String.format(".filter-dropdowns li:not(:first)")).filter(function () {
        $(this).toggle($(this).text().trim().toLowerCase().indexOf(value) > -1);
      });
    },

    getFileNames() {
      debugger;
      var files = require.context("@/assets/images/flags/", true, /\.png$/);
      files
        .keys()
        .map((key) => key.slice(2))
        .forEach((file) => {
          var fileName = file.split(".")[0];
          this.locales.push({
            file: file,
            name: fileName,
            text: this.$t(
              "Components.LanguageFlagSelectBox." + fileName,
              {},
              { locale: this.$store.state.activeLang }
            ),
            path: this.filePath + file,
          });
        });
    },
  },
  mounted() {
    this.getFileNames();
  },
};
</script>
